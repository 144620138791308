// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {BuoIfr6NY: {hover: true}};

const serializationHash = "framer-Uzj9k"

const variantClassNames = {BuoIfr6NY: "framer-v-d2q2ha"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, MEtLpYcIg: link ?? props.MEtLpYcIg, txAuXfMvc: icon ?? props.txAuXfMvc ?? {src: "https://framerusercontent.com/images/dzy2fU5uUTG4Qc1EQfhDcDYbU4.svg"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;icon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MEtLpYcIg, txAuXfMvc, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "BuoIfr6NY", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={MEtLpYcIg}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-d2q2ha", className, classNames)} framer-13hbr5z`} data-framer-name={"Social Button"} layoutDependency={layoutDependency} layoutId={"BuoIfr6NY"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-a78865fe-5c5b-409b-873a-e95bcf71ff8a, rgb(24, 24, 26))", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} variants={{"BuoIfr6NY-hover": {backgroundColor: "var(--token-52be73dd-1bd3-4201-83f2-487c9f21864d, rgb(29, 29, 32))"}}} {...addPropertyOverrides({"BuoIfr6NY-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (10 + ((((componentViewport?.height || 44) - 20) - 24) / 2)))), pixelHeight: 24, pixelWidth: 25, sizes: "24px", ...toResponsiveImage(txAuXfMvc), ...{ positionX: "center", positionY: "center" }}} className={"framer-16fpc6k"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"r0RT7lU4m"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Uzj9k.framer-13hbr5z, .framer-Uzj9k .framer-13hbr5z { display: block; }", ".framer-Uzj9k.framer-d2q2ha { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 10px; position: relative; text-decoration: none; width: min-content; }", ".framer-Uzj9k .framer-16fpc6k { flex: none; height: 24px; overflow: visible; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Uzj9k.framer-d2q2ha { gap: 0px; } .framer-Uzj9k.framer-d2q2ha > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Uzj9k.framer-d2q2ha > :first-child { margin-left: 0px; } .framer-Uzj9k.framer-d2q2ha > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"PfKwchPaY":{"layout":["auto","auto"]}}}
 * @framerVariables {"MEtLpYcIg":"link","txAuXfMvc":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDatBemFd1: React.ComponentType<Props> = withCSS(Component, css, "framer-Uzj9k") as typeof Component;
export default FramerDatBemFd1;

FramerDatBemFd1.displayName = "Buttons/ Social Button";

FramerDatBemFd1.defaultProps = {height: 44, width: 44};

addPropertyControls(FramerDatBemFd1, {MEtLpYcIg: {title: "Link", type: ControlType.Link}, txAuXfMvc: {__defaultAssetReference: "data:framer/asset-reference,dzy2fU5uUTG4Qc1EQfhDcDYbU4.svg?originalFilename=email-svgrepo-com+%281%29+1+%289%29.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerDatBemFd1, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})